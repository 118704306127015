















import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  props: ["items"],
  methods: {},
})
export default class Breweries extends Vue {}
