




















































































































import { Component, Vue } from "vue-property-decorator";
import { DEVICES } from "@/graphql/Device.js";
import { gql } from "apollo-server-lambda";
import moment from "moment";

export const StartBatch = gql`
  mutation StartBatch($input: StartBatchInput!) {
    startBatch(input: $input) {
      name
      id
    }
  }
`;

@Component({
  components: {},
  computed: {},
  props: ["brewery"],
  data: () => ({
    batchNameRules: [
      (value: any) => !!value || "Required.",
      (value: any) => (value || "").length <= 75 || "Max 75 characters",
      (value: any) => (value || "").length >= 3 || "Min 3 characters",
    ],
    batchNumberRules: [
      (value: any) => !!value || "Required.",
      (value: any) => (value || 0) > 0 || "Must be greater than 0.",
    ],
    originalGravityRules: {
      SG: [
        (value: any) => !!value || "Required.",
        (value: any) => (value || 0) >= 1.0 || "SG must be greater than 1.0",
        (value: any) => (value || 0) <= 1.15 || "SG must be less than 1.15",
      ],
      PLATO: [
        (value: any) => !!value || "Required.",
        (value: any) => (value || 0) >= 0 || "Plato must be greater than 0.",
        (value: any) => (value || 0) <= 30 || "Plato must be greater than 30.",
      ],
      BRIX: [
        (value: any) => !!value || "Required.",
        (value: any) => (value || 0) >= 0 || "Brix must be greater than 0.",
        (value: any) => (value || 0) <= 30 || "Brix must be greater than 30.",
      ],
    },
    email: [
      (value: any) => !!value || "Required.",
      (value: any) => (value || "").length <= 40 || "Max 40 characters",
      (value: string) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    ],
    input: {
      deviceId: null,
      vesselId: null,
      batchName: null,
      batchNumber: null,
      originalGravity: null,
      originalGravityUnit: "SG",
      liquidVolume: null,
      liquidVolumeUnit: "LITER",
    },
    dialog: false,
    DEVICES,
    moment,
  }),
  methods: {
    tblink(id, name) {
      return `https://thingsboard.cloud/dashboardGroups/fffaa410-8a8d-11eb-940b-d3370bfe370a/f06380c0-dda4-11eb-9d0e-1f8899a6f9b3?state=${btoa(
        JSON.stringify([
          {
            id: "default",
            params: {
              entityId: { entityType: "DEVICE", id },
              entityName: name,
            },
          },
        ])
      )}`;
    },
    async startBatch() {
      console.log("start batch", {
        breweryId: (this as any).brewery.id,
        input: (this as any).input,
      });

      (this as any).$refs.form.validate();

      const r = await (this as any).$apollo.mutate({
        mutation: StartBatch,
        variables: {
          input: {
            ...(this as any).input,
            batchNumber: parseInt((this as any).input.batchNumber),
            liquidVolume: parseFloat((this as any).input.liquidVolume),
            originalGravity: parseFloat((this as any).input.originalGravity),
            breweryId: (this as any).brewery.id,
          },
        },
        update: (store: any, { data: { startBatch }, errors }: any) => {
          console.log("update", errors, startBatch);
          if (startBatch) (this as any).dialog = false;
        },
        optimisticResponse: {
          __typename: "Mutation",
          allocateDevice: {
            __typename: "Batch",
            id: -1,
            name: "Saving...",
          },
        },
      });
      console.info("result", r, this);
    },
  },
})
export default class Breweries extends Vue {}
