var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',[_c('ApolloQuery',{attrs:{"query":_vm.DEVICES,"variables":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var isLoading = ref.isLoading;
return [(isLoading)?_c('div',{staticClass:"loading apollo"},[_vm._v("Loading...")]):(error)?_c('div',{staticClass:"error apollo"},[_vm._v(" An error occured "),_c('pre',[_vm._v(_vm._s(error))])]):(data)?_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-right mt-5 mr-5",attrs:{"color":"primary","outlined":""}},'v-btn',attrs,false),on),[_vm._v("Add Device")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title'),_c('v-container',{attrs:{"fluid":""}},[_c('v-autocomplete',{staticClass:"mt-6",attrs:{"items":data.devices.map(function (x) { return ({
                  name: x.brewery ? ((x.name) + " (" + (x.brewery.name) + ")") : x.name,
                  id: x.id,
                  disabled: x.brewery != null,
                }); }),"item-text":"name","item-value":"id","item-disabled":"disabled","label":"Select device","dense":"","outlined":""},model:{value:(_vm.deviceId),callback:function ($$v) {_vm.deviceId=$$v},expression:"deviceId"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.locationOptions,"label":"Current Location","dense":"","outlined":""}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"inset":"","color":"success","label":("WiFi: " + (_vm.wifiConfigured ? 'configured' : 'NOT CONFIGURED'))},model:{value:(_vm.wifiConfigured),callback:function ($$v) {_vm.wifiConfigured=$$v},expression:"wifiConfigured"}})],1)],1),_c('v-row',{staticClass:"mt-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"filled":"","rows":"2","name":"input-7-3","label":"Internal Remarks"}})],1)],1)],1),(_vm.gdevice(data.devices).id)?_c('v-simple-table',{attrs:{"fixed-header":"","height":"400px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Property")]),_c('th',{staticClass:"text-left"},[_vm._v("Value")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Name")]),_c('td',[_vm._v(_vm._s(_vm.gdevice(data.devices).name))])]),_c('tr',[_c('td',[_vm._v("Last Active")]),_c('td',[_vm._v(" "+_vm._s(_vm.moment( new Date(_vm.gdevice(data.devices).lastActive), "YYYYMMDD" ).fromNow())+" ")])]),_c('tr',[_c('td',[_vm._v("Location")]),_c('td',[_vm._v(_vm._s(_vm.gdevice(data.devices).location.place))])]),_c('tr',[_c('td',[_vm._v("ThingsBoardId")]),_c('td',[_c('a',{attrs:{"href":_vm.tblink(
                          _vm.gdevice(data.devices).thingsBoardId,
                          _vm.gdevice(data.devices).name
                        ),"target":"_blank"}},[_vm._v("Realtime Dashboard")])])]),_c('tr',[_c('td',[_vm._v("Serial")]),_c('td',[_vm._v(_vm._s(_vm.gdevice(data.devices).serialNumber))])])])]},proxy:true}],null,true)}):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":!_vm.deviceId && _vm.brewery && !_vm.brewery.id,"text":""},on:{"click":function (x) { return _vm.allocateDevice(data.devices); }}},[_vm._v(" Save ")])],1)],1)],1):_c('div',{staticClass:"no-result apollo"},[_vm._v("No result :(")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }