












































































import { Component, Vue } from "vue-property-decorator";
import Login from "@/components/okta-login.vue"; // @ is an alias to /src
// import Breweries from "@/components/breweries.vue"; // @ is an alias to /src
import Vessels from "@/components/vessels.vue";
import Devices from "@/components/devices.vue";
import Batches from "@/components/batches-advanced.vue";
import StartBatch from "@/components/start-batch-dialog.vue";
import { BREWERY_BY_ID } from "@/graphql/Brewery";
import AddDeviceDialog from "@/components/add-device-to-brewery.vue";

@Component({
  components: {
    Login,
    // Breweries,
    Vessels,
    Batches,
    Devices,
    AddDeviceDialog,
    StartBatch,
  },
  computed: {},
  data: () => ({ BREWERY_BY_ID }),
  methods: {},
})
export default class Home extends Vue {}
