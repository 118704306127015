































































































































































import { Component, Vue } from "vue-property-decorator";
import { DEVICES, deviceAllocateToBrewery } from "@/graphql/Device.js";
import { breweryAllocateDevice } from "@/graphql/Brewery";
import { gql } from "apollo-server-lambda";
import moment from "moment";

export const AllocateDevice = gql`
  mutation AllocateDevice(
    $deviceId: ID!
    $breweryId: ID!
    $checklist: [String!]!
  ) {
    allocateDevice(
      breweryId: $breweryId
      deviceId: $deviceId
      checklist: $checklist
    ) {
      name
      id
    }
  }
`;

@Component({
  components: {},
  computed: {
    assets() {
      return this.$store.state.assets;
    },
  },
  props: ["brewery"],
  data: () => ({
    deviceId: null,
    dialog: false,
    locationOptions: [
      "STORAGE",
      "CALIBRATION_BENCH",
      "READY_TO_BE_SHIPPED",
      "SHIPPING",
      "BREWERY",
      "MOUNTED",
    ],
    wifiConfigured: false,
    DEVICES,
    moment,
  }),
  methods: {
    tblink(id, name) {
      return `https://thingsboard.cloud/dashboardGroups/fffaa410-8a8d-11eb-940b-d3370bfe370a/f06380c0-dda4-11eb-9d0e-1f8899a6f9b3?state=${btoa(
        JSON.stringify([
          {
            id: "default",
            params: {
              entityId: { entityType: "DEVICE", id },
              entityName: name,
            },
          },
        ])
      )}`;
    },
    gdevice(devices) {
      return (
        (devices || []).find((x: any) => x.id === (this as any).deviceId) || {}
      );
    },
    async allocateDevice(devices) {
      console.log("allocate device", {
        devices,
        brewery: (this as any).brewery,
        deviceId: (this as any).deviceId,
        breweryId: (this as any).brewery?.id,
      });

      let device = devices.find((x: any) => x?.id === (this as any).deviceId);
      console.log("device");
      const r = await (this as any).$apollo.mutate({
        mutation: AllocateDevice,
        variables: {
          deviceId: (this as any).deviceId,
          breweryId: (this as any).brewery.id,
          checklist: [],
        },
        update: (store: any, { data: { allocateDevice }, errors }: any) => {
          console.log("update");

          if (allocateDevice) {
            deviceAllocateToBrewery(store, (this as any).deviceId, {
              __typename: "Brewery",
              id: (this as any).brewery.id,
              name: (this as any).brewery.name,
            });
            console.log("deviceAllocateToBrewery done");

            breweryAllocateDevice(store, (this as any).brewery.id, (x: any) => {
              x.push({
                __typename: "Device",
                id: device.id,
                name: device.name,
              });
              return x;
            });
            console.log("breweryAllocateDevice done");

            (this as any).dialog = false;
          } else {
            alert("Failed to allocate");
          }
        },
        optimisticResponse: {
          __typename: "Mutation",
          allocateDevice: {
            __typename: "Brewery",
            id: -1,
            name: "Saving...",
          },
        },
      });
      console.info("result", r, this);
    },
  },
})
export default class Breweries extends Vue {}
