var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[(!_vm.$store.state.okta_token)?_c('Login'):_vm._e(),(_vm.$store.state.okta_token)?_c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('ApolloQuery',{attrs:{"query":_vm.BREWERY_BY_ID,"variables":{ id: this.$route.params.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var isLoading = ref.isLoading;
return [(isLoading)?_c('div',{staticClass:"loading apollo"},[_vm._v("Loading...")]):(error)?_c('div',{staticClass:"error apollo"},[_vm._v(" An error occured "+_vm._s(error)+" ")]):(data)?_c('div',{staticClass:"result apollo"},[_c('v-card',{staticClass:"mx-auto"},[_c('v-btn',{staticClass:"float-right mt-5 mr-5",attrs:{"color":"primary","outlined":"","disabled":""}},[_vm._v("Add Vessel")]),_c('AddDeviceDialog',{attrs:{"brewery":data.breweryById}}),_c('v-card-title',[_vm._v(" "+_vm._s(data.breweryById.name)+" ")]),_c('v-card-actions',[_c('Devices',{attrs:{"items":data.breweryById.devices}})],1)],1)],1):_c('div',{staticClass:"no-result apollo"},[_vm._v("No result :(")])]}}],null,false,1326484731)})],1)],1)],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('ApolloQuery',{attrs:{"query":require('../graphql/BatchesByBreweryId.gql'),"variables":{ id: this.$route.params.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var isLoading = ref.isLoading;
return [(isLoading)?_c('div',{staticClass:"loading apollo"},[_vm._v("Loading...")]):(error)?_c('div',{staticClass:"error apollo"},[_vm._v(" An error occured "+_vm._s(error)+" ")]):(data)?_c('div',{staticClass:"result apollo"},[_c('Batches',{staticClass:"mx-auto mt-5",attrs:{"items":data.batchesByBreweryId,"hideColumns":['brewery.name']}})],1):_c('div',{staticClass:"no-result apollo"},[_vm._v("No result :(")])]}}],null,false,39348238)})],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }